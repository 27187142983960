.main-footer {
    position: fixed;
    bottom: 0px;
    width: 100%;
}

button {
    margin-left: 5px;
}

.table-actions {
    width: 150px;
}

.table-hover tbody tr:hover {
    color: #212529;
    background-color: rgb(188, 199, 235);
}
.map-container {
  width: 100%;
  height: 100%;
}

.switch-vertical {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 60px;
  }
  
  .switch-vertical input[type="checkbox"] {
    display: none;
  }
  
  .slider-vertical {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
  }
  
  .slider-vertical:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 20px;
    left: 10%;
    bottom: 5px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider-vertical {
    background-color: #2196F3;
  }
  
  input:focus + .slider-vertical {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider-vertical:before {
    left: 50%;
    -webkit-transform: translateY(-28px) translateX(-50%);
    transform: translateY(-28px) translateX(-50%);
  }
  
.wrapper {
	background-color: #fff!important;
}

.login-box-body {
	background-color: #eee;
}

.login-box button {
	margin-left: 0px;
}

.login-box a:hover {
	cursor: pointer;
}

